@tailwind base;
@tailwind components;
@tailwind utilities;

.nepali-date-picker input {
    @apply w-full border rounded p-2;
}

.form_label {
    @apply block text-sm font-medium text-gray-700 mb-1
}

.form_input {
    @apply w-full px-3 py-2 border rounded-md focus:ring-2 focus:ring-blue-500
}